import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pageloader } from '../Components/Pageloader';
import { useSelector } from 'react-redux';
import { RefreshVists, OpenChat, ChatDetails, callUser, ToastNotify, VisitorSound } from '../Actions';
import { useDispatch } from 'react-redux';
import { videonotify } from '../Helper/Dranzer';
import { Button } from 'react-bootstrap';
import './css/ExeLiveVisits.css'

const ExeLiveVisits = ({ commonData }) => {
    const phptoken = useSelector(state => state.phpToken);
    const user = useSelector(state => state.userDetails);
    const reflist = useSelector(state => state.RefVisits);
    const visiSound = useSelector(state => state.VisitorSounds);
    const dispatch = useDispatch();

    const [refresh, setrefresh] = useState("");
    const [visitors, setvisitors] = useState([]);
    const [pageloader, setPageLoader] = useState(false);
    useEffect(() => {

        async function gettask() {

            setPageLoader(true);
            let url = commonData.siteurl + '/virtualexpo_api/getexelivevisits/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                visitor_id: user.accountid,
                visitor_type: user.account_type,
                stallid: user.stall_id
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    setvisitors(res.data[0].DATA.task);
                    setPageLoader(false);
                }

            }

        }

        gettask();
    }, [refresh]);

    const refreshList = () => {
        setrefresh(Math.floor(Math.random() * 1011))
    }

    useEffect(() => {
        if (reflist.num != 1) {
            setrefresh(reflist.num);
            dispatch(RefreshVists("1"));
        }

    }, [reflist])

    const openChatBox = (id, name, photo) => {
        localStorage.setItem("active_chat_tab", "chats");
        dispatch(OpenChat(true));
        dispatch(ChatDetails(true, id, name, photo, "", "", "", ""));
    }
    const openVideoCall = (id, name, receiver) => {
        videonotify(commonData.siteurl, commonData.AuthorizeKey, phptoken, user.accountid, user.account_type, receiver, user.account_type, "Video call request by " + user.username, commonData.NOTIFY_VIDEOCALL, "")
        async function checkVideoChat() {
            let url = commonData.siteurl + '/virtualexpo_api/checkVideoChat/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                id: id
            }, headers);
            if (res) {
                if (res.data[0].DATA) {
                    if (res.data[0].DATA == "busy") {
                        dispatch(ToastNotify(true, "Error", "Busy right now, Please call after sometime.", "danger"));
                    } else {
                        dispatch(callUser(true, id, name, receiver, user.accountid));
                    }

                }

            }
        }

        checkVideoChat()

    }

    const StopSound = () =>{
        dispatch(VisitorSound(false))
    }
    return (
        <div className="live-visitor-view">
            <div className="card">
                <div className="header-titles">
                    <h4 className="card-title font-bold">Live Visitors  
                    <Button className='float-right btn btn-primary btnBlue ' onClick={() => { refreshList() }} > refresh </Button>
                    {visiSound.on
                    ?  <Button className='float-right btn btn-danger mx-2' onClick={() => { StopSound() }} > Stop Sound </Button>
                    : ""
                    }
                    </h4>
                    <h6 className="card-subtitle">List of your visitor on your stall</h6>
                </div>
                <div className="card-body">

                    {pageloader
                        ? <Pageloader />
                        :
                        [visitors
                            ?
                            <div className="exeLiveList-view">
                                {visitors.map((t) => (
                                    <div key={`task_${t.eul_id}`} className="col-12 row ell-row mb-4">
                                        <div className="col-8">
                                            <h5>{t.name} ({t.designation})</h5>
                                            <h6>{t.company}</h6>
                                            <h6>{t.in_time}</h6>
                                        </div>
                                        <div className="col-4">
                                            <Button className='float-right btnOrange' onClick={() => { openChatBox(t.contact_id, t.name, t.photo_path) }} > <img src="/stall/icon/executive/chat.png" alt="chat" width="30px" height="30px" /> </Button>
                                            {/* <Button className='float-right' onClick={() => { openVideoCall(t.socket_id,t.name,t.contact_id) }} > <img src="/stall/icon/executive/video-call.png" alt="video call" width="30px" height="30px" /> </Button> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            : "Did not found any visitor."
                        ]
                    }
                </div>
            </div>
        </div>
    )
}

export default ExeLiveVisits
