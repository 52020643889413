import React, {useEffect} from 'react'
import { Footer } from './Footer';
import { Header } from './Header';
export const Cookies = () => {
    document.title = "Cookies Policy Page";
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <Header />
            <div className="aboutWB bg-white">
                <div className="image-bg-slider1" style={{ background: "url(/images/website/Contact_us_Banner.png)" }}>
                    <div className="business-platform">
                        <h4>Cookies Policy</h4>
                    </div>
                </div>

                <div className="WB-Privacy">
                    <div className="privacy-para1">
                        <p>Our website uses cookies, tracking pixels, and related technologies. Cookies are small data files that are served by our platform and stored on your device. Our site uses cookies dropped by us or third parties for a variety of purposes including operating and personalizing the website. Also, cookies may also be used to track how you use the site to target ads to you on other websites. By visiting our website third parties (such as AdRoll) may place cookies on users’ browsers for targeted advertising purposes. Types of data such as IP addresses, cookie identifiers, and website activity may be traced and collected for targeted advertising and analytics purpose. This data may be used by third parties (such as AdRoll) to target advertising on other sites based on your user’s online activity. You can opt out from targeting ads from here <a href="http://www.aboutads.info/" target='_blank'> aboutads.info</a> or the NAI opt-out site at <a href="http://www.networkadvertising.org/choices/" target='_blank'> networkadvertising.org/choices</a>, or for those in Europe, the EDAA opt-out site at <a href='http://www.youronlinechoices.com/' target='_blank'> youronlinechoices.eu.</a>
                        </p>

                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
