import React from 'react'
import { Homepage } from '../Layouts/Homepage'

export const Home = ({commonData}) => {
   
    return (
        <div style={commonData.minHeight}>
              <Homepage commonData={commonData} /> 
        </div>
    )
}
