let initial;

    initial = { on: false, title: "", msg: "" ,eb_id: "", stall_name: "", hall_name: "", audio: "" };


const initials = initial;
const AlertNotify = (state= initials , action ) =>{
    if(action.type === "ALERTNOTIFY" ){
        return {on:action.on, 
            title:action.title,
             msg:action.msg,
             eb_id:action.eb_id,
             stall_name:action.stall_name,
             hall_name:action.hall_name,
             audio:action.audio, 
            };
    }else{ 
        return state;
    }
   
}

export default AlertNotify;