import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Pageloader } from '../Components/Pageloader';
import ReactPlayer from 'react-player';
import { Modal, Button, Form, ModalFooter } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Preloader } from '../Components/Preloader';
import { ToastNotify } from '../Actions';
import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import serialize from 'form-serialize';
import { useDispatch } from 'react-redux';
import useRecorder from './useRecorder';

export const BroadCast = ({ commonData, BcStall, BcHall }) => {
    let [audioURL, isRecording, startRecording, stopRecording, blobData] = useRecorder();

    const phptoken = useSelector(state => state.phpToken);
    const user = useSelector(state => state.userDetails);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();

    const [BroadCast, setBroadCast] = useState([]);
    const [pageloader, setPageLoader] = useState(false);
    const [refresh, setrefresh] = useState("");
    const [audioList, setaudioList] = useState([]);
    useEffect(() => {
        async function getbroadcast() {
            setPageLoader(true);
            let url = commonData.siteurl + '/virtualexpo_api/getbroadcast/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                visitor_id: user.accountid,
                visitor_type: user.account_type
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    setBroadCast(res.data[0].DATA.broadcast);
                    setaudioList(res.data[0].DATA.audiolist);
                    setPageLoader(false);
                }

            }

        }

        getbroadcast();
    }, [refresh]);

    // code for modals
    const [submitloader, setSubmitLoader] = useState(false);
    const [BCShow, setBCShow] = useState(false);
    const BCClose = () => {
        setBCShow(false);
    }
    const [stDt, setstDt] = useState(moment());
    const [endDt, setendDt] = useState("");
    const onBCSubmit = (data) => {
        setSubmitLoader(true);
        async function submitBC() {
            const startDate = stDt ? stDt.format('L LT') : "";
            const endDate = endDt ? endDt.format('L LT') : "";

            //validation code start here
            if (startDate == "") {
                dispatch(ToastNotify(true, "ERROR", "Start dat cannot be blank", "danger"));
                setSubmitLoader(false);
                return false;
            }

            if (endDate == "") {
                dispatch(ToastNotify(true, "ERROR", "End dat cannot be blank", "danger"));
                setSubmitLoader(false);
                return false;
            }
            if(data.audiotype == "U"){
            if (data.formFile[0].size > 5097152) {
                dispatch(ToastNotify(true, "ERROR", "Filesize should be less than 5MB", "danger"));
                setSubmitLoader(false);
                return false;

            }
            if (!(data.formFile[0].type == 'audio/mpeg')) {
                dispatch(ToastNotify(true, "ERROR", "Invalid File Type Only MP3 files allowed", "danger"));
                setSubmitLoader(false);
                return false;

            }
        }
            let urls = commonData.siteurl + '/virtualexpo_api/savebroadcast/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const form = document.querySelector('#broadcast-form');

            const obj = serialize(form, { hash: true });
            const fd = new FormData();

            Object.keys(obj).forEach(function (key) {
                fd.append(key, (obj[key]));
            });
            if(data.audiotype == "U"){
            fd.append('audio', data.formFile[0], data.formFile[0].name);
            }else if(data.audiotype == "R"){
                fd.append('audiofilename', selectAudiofilename);
            }else{
                fd.append('audiofilename', selectAudioURL);
            }
            
            fd.append("AUTHORIZEKEY", (commonData.AuthorizeKey));
            fd.append("PHPTOKEN", (phptoken));
            fd.append("bc-st", (startDate));
            fd.append("bc-et", (endDate));
            fd.append("visitor_id", (user.accountid));
            fd.append("visitor_type", (user.account_type));
            const ress = await axios.post(urls, fd, headers);

            if (ress.data[0].STATUS == "SUCCESS") {
                setrefresh(Math.random() * 1000);
                BCClose();
                dispatch(ToastNotify(true, "SUCCESS", ress.data[0].MSG, "success"));
                reset();
                setSubmitLoader(false);

            } else {
                dispatch(ToastNotify(true, "Error", ress.data[0].MSG, "danger"));
                setSubmitLoader(false);
            }

        }
        submitBC();
    }
    const audioType = (e) => {
        if (e.target.value) {
            if (e.target.value == "R") {
                document.getElementById('audiofile').style.display = 'none';
                document.getElementById('selectaudiofile').style.display = 'none';
                document.getElementById('audioRecorder').style.display = 'block';
            }
            else if(e.target.value == "S"){
                document.getElementById('audiofile').style.display = 'none';
                document.getElementById('audioRecorder').style.display = 'none';
                document.getElementById('selectaudiofile').style.display = 'block';
            } else {
                document.getElementById('audioRecorder').style.display = 'none';
                document.getElementById('selectaudiofile').style.display = 'none';
                document.getElementById('audiofile').style.display = 'block';
            }
        } else {
            document.getElementById('audiofile').style.display = 'none';
            document.getElementById('audioRecorder').style.display = 'none';
            document.getElementById('selectaudiofile').style.display = 'none';
        }

    }
    const [fileshow, setfileshow] = useState(false);

    const filehandleClose = () => setfileshow(false);
    const filehandleShow = () => setfileshow(true);
    const [selectAudiofilename, setselectAudiofilename] = useState("")
    const filehandle = () => {
        var element = document.getElementById('filename');
       
        if (element.value == "") {
            dispatch(ToastNotify(true, "Error", "Please enter file name.", "danger"));
            return false;
        }
       
        async function submitfile() {
            let urls = commonData.siteurl + '/virtualexpo_api/saverecorderfile/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const fd = new FormData();
            fd.append('fname', element.value);
            fd.append('data', blobData);
            fd.append("AUTHORIZEKEY", (commonData.AuthorizeKey));
            fd.append("PHPTOKEN", (phptoken));
            const ress = await axios.post(urls, fd, headers);

            if (ress.data[0].STATUS == "SUCCESS") {
                dispatch(ToastNotify(true, "SUCCESS", ress.data[0].MSG, "success"));
                element.value = "";
                setfileshow(false)
                setaudioListRefresh(Math.random() * 1000);
                setselectAudiofilename(ress.data[0].DATA);
            } else {
                dispatch(ToastNotify(true, "Error", ress.data[0].MSG, "danger"));
            }
        }
       
        if (blobData.size > 0) {
            submitfile();
        }
    }
    
    const [audioListRefresh, setaudioListRefresh] = useState("");
    useEffect(() => {
        async function getbroadcasAudioList() {
            let url = commonData.siteurl + '/virtualexpo_api/getbroadcast_audiolist/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                visitor_id: user.accountid,
                visitor_type: user.account_type
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    setaudioList(res.data[0].DATA.audiolist); 
                }
            }
        }

        getbroadcasAudioList();
    }, [audioListRefresh]);

    const [selectAudioURL, setselectAudioURL] = useState("");
    const changeAudio = (e) =>{
        if(e.target.value){
            setselectAudioURL(e.target.value);
        }else{
            setselectAudioURL("");
        }
        
    }
    return (
        <div>

            <div className="broadcast-view">
                <div className="card">
                    <div className="header-titles">
                        <h4 className="card-title font-bold">Broadcast list <Button onClick={() => { setBCShow(true) }} className="btn btn-sm btn-primary float-right">Add</Button></h4>
                        <h6 className="card-subtitle">List of your Broadcast</h6>
                    </div>
                    {pageloader
                        ? <Pageloader />
                        :
                        [BroadCast.length > 0
                            ?

                            <div className="card-body">
                                {BroadCast.map((bc, index) => (
                                    <div key={`bc_${index}`} className="bc-row col-12 row">
                                        <div className="col-1">
                                            {index + 1})
                                        </div>
                                        <div className="col-11">
                                            <h5> {bc.title}  <span className="label label-danger"> Seen By : {bc.seen} </span> </h5>
                                            <small>Start Date: {bc.start_dt} / End Date:{bc.end_dt}</small><br />
                                            <small>Message : {bc.msg}</small><br />
                                            <small>Viewer : {bc.viewer} </small><br />
                                            {bc.stall
                                                ?
                                                <small>Stall Name : {bc.stall}  <br /> </small>
                                                : ""
                                            }
                                            {bc.hall
                                                ? <small>Hall Name : {bc.hall} </small>
                                                : ""
                                            }
                                            {bc.audio
                                                ?
                                                <ReactPlayer controls width='100%' height='50px' url={`${commonData.siteurl}/public/expofiles/${commonData.sitename}/audio/${bc.audio}`} />
                                                : ""
                                            }

                                        </div>
                                    </div>
                                ))}

                            </div>





                            : "Did not found any broadcast."
                        ]
                    }
                </div>

            </div>


            <Modal size="xl" show={BCShow} onHide={BCClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Broadcast </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form id="broadcast-form" className="col-12 row" onSubmit={handleSubmit(onBCSubmit)}>
                        <Form.Group className="mb-3 col-md-6" controlId="titlename">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Enter Title Name" {...register('titlename', { required: true })} />
                            <Form.Text className="text-danger">
                                {errors.titlename && <span>This field is required</span>}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="broadviewer">
                            <Form.Label>Who will view this broadcast</Form.Label>
                            <Form.Select aria-label="Default select example" {...register('broadviewer', { required: true })}>
                                <option value={commonData.Visitor}>{commonData.Visitor_name}</option>
                                <option value={commonData.Agent}>{commonData.Agent_name}</option>
                                <option value={commonData.Both}>Both</option>
                            </Form.Select>
                            <Form.Text className="text-danger">
                                {errors.broadviewer && <span>This field is required</span>}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6">
                            <Form.Label>Start Date</Form.Label>
                            <DatePicker value={stDt} dateFormat="DD-MM-YYYY" timeFormat="hh:mm A" onChange={val => setstDt(val)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6">
                            <Form.Label>End Date</Form.Label>
                            <DatePicker value={endDt} dateFormat="DD-MM-YYYY" timeFormat="hh:mm A" onChange={val => setendDt(val)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="broadstall">
                            <Form.Label>Select stall</Form.Label>
                            <Form.Select aria-label="Default select example" {...register('broadstall')}>
                                <option value="">Open this select stall</option>
                                {BcStall ?
                                    [BcStall.map((bcs) => (
                                        <option key={`bcs_${bcs.SupportRequest.order_id}`} value={bcs.SupportRequest.order_id}>{bcs.Contact.name}</option>
                                    ))] : ""}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="broadhall">
                            <Form.Label>Select Hall</Form.Label>
                            <Form.Select aria-label="Default select example" {...register('broadhall')}>
                                <option value="">Open this select hall</option>
                                {BcHall ?
                                    [BcHall.map((hall) => (
                                        <option key={`bchall_${hall.Expo_Auditorium_Hall.eah_id}`} value={hall.Expo_Auditorium_Hall.eah_id}>{hall.Expo_Auditorium_Hall.name}</option>
                                    ))] : ""}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-12" controlId="broasmsg">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} {...register('broasmsg', { required: true })} />
                            <Form.Text className="text-danger">
                                {errors.broasmsg && <span>This field is required</span>}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="audiotype">
                            <Form.Label>Select Audio Type</Form.Label>
                            <Form.Select aria-label="Default select example" {...register('audiotype')} onChange={(e) => { audioType(e) }}>
                                <option value="">Select Audio Type</option>
                                <option value="U">Upload File</option>
                                <option value="R">Record File</option>
                                <option value="S">Select File</option>
                            </Form.Select>
                        </Form.Group>
                        <div id="audiofile" className='dn'>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Add Audio (Use .mp3 format audio file and file size should be less than 5MB.)</Form.Label>
                                <Form.Control type="file" {...register('formFile')} />
                            </Form.Group>
                        </div>
                        <div id="selectaudiofile" className='dn'>
                        <Form.Group className="mb-3 col-md-12" controlId="audioselect">
                            <Form.Label>Select audio</Form.Label>
                            <Form.Select aria-label="Default select example" {...register('audioselect')} onChange={(e)=>{changeAudio(e)}}>
                                <option value="">Open this select audio</option>
                                {audioList ?
                                    [audioList.map((aud) => (
                                        <option key={`aud_${aud.Expo_BroadCast_Files.filename}`} value={aud.Expo_BroadCast_Files.filename}>{aud.Expo_BroadCast_Files.filename}</option>
                                    ))] : ""}
                            </Form.Select>
                        </Form.Group>
                        {selectAudioURL
                            ? <audio className='col-12' src={`${commonData.siteurl}/public/expofiles/${commonData.sitename}/audio/${selectAudioURL}`} controls />
                            : ""
                        }
                        </div>
                        <div id="audioRecorder" className='dn'>
                     
                            <audio className='col-12' src={audioURL} controls />
                            <a className='btn btn-primary m-2 text-light' onClick={startRecording} disabled={isRecording}>
                                Start Recording
                            </a>
                            <a className='btn btn-primary m-2 text-light' onClick={stopRecording} disabled={!isRecording}>
                                Stop Recording
                            </a>
                            {audioURL
                                ?
                                <a className='btn btn-primary m-2 text-light' onClick={() => { filehandleShow() }} >
                                    Save Recording
                                </a>
                                : ""

                            }

                        </div>
                        <ModalFooter>
                            <Button variant="secondary" onClick={BCClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit" disabled={submitloader}>
                                Save
                            </Button>
                            <div className="w-100 text-center submitloader mt-3">
                                {submitloader ?
                                    <>
                                        <Preloader />
                                    </>
                                    : ""
                                }
                            </div>
                        </ModalFooter>
                    </Form>
                </Modal.Body>

            </Modal>

            <Modal show={fileshow} onHide={filehandleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter File Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="text" name='filename' id='filename' className='form-control' />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={filehandle}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
