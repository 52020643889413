import React from 'react';
import Home from '../Website/Home';
export const Website = ({commonData}) => {
   

    return (
        <div>
           <Home  commonData={commonData} />
            </div>
    )
}
