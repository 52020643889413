import React, { useState, useEffect } from 'react'
import { Chart } from "react-google-charts";
import axios from 'axios';
import { Pageloader } from '../Components/Pageloader';
import Modal from 'react-bootstrap/Modal'
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { Preloader } from '../Components/Preloader';
import moment from 'moment';
import { ModalFooter } from 'react-bootstrap';
import { Form, Button } from 'react-bootstrap';
import { ToastNotify } from '../Actions';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSync} from '@fortawesome/free-solid-svg-icons'

export const options = {
    chart: {
        title: "Hall wise graph",
        subtitle: "",
    },
  
    bars: "horizontal",
    axes: {
        y: {
            0: { side: "bottom" },
        },
        colors: ['yellow','yellow', 'blue'],
    },
};
export const OrgHallWise = ({ commonData, phptoken, user }) => {
    const dispatch = useDispatch();
    const [pageloader, setPageLoader] = useState(false);
    const [PageWise, setPageWise] = useState([]);
    const [stDt, setstDt] = useState();
    const [endDt, setendDt] = useState();
    useEffect(() => {
        async function getPageWiseGraph() {
            setPageLoader(true);
            let url = commonData.siteurl + '/virtualexpo_api/getHallWiseGraph/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                visitor_id: user.accountid,
                visitor_type: user.account_type
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    setPageWise(res.data[0].DATA.log);
                    if(res.data[0].DATA.stdt){
                        setstDt(moment(res.data[0].DATA.stdt, "DD-MM-YYYY hh:mm A"));
                    }
                    if(res.data[0].DATA.enddt){
                        setendDt(moment(res.data[0].DATA.enddt, "DD-MM-YYYY hh:mm A"));
                    }
                    setPageLoader(false);
                }

            }

        }

        getPageWiseGraph();
    }, []);

    const [pwModalShow, setpwModalShow] = useState(false);
    const [submitloader, setsubmitloader] = useState(false)
    const pwModalClose = () => {
        setpwModalShow(false)
    }
    const submitpwGraph = () => {
        setsubmitloader(true);
        setPageLoader(true)
        const startDate = stDt ? stDt.format('L LT') : "";
        const endDate = endDt ? endDt.format('L LT') : "";

        if (!startDate) {
            dispatch(ToastNotify(true, "ERROR", "Start date cannot be blank", "danger"));
            setsubmitloader(false);
            setPageLoader(false)
            return false;
        }
        if (!endDate) {
            dispatch(ToastNotify(true, "ERROR", "End date cannot be blank", "danger"));
            setsubmitloader(false);
            setPageLoader(false)
            return false;
        }

        async function getPageWiseGraph() {
            let url = commonData.siteurl + '/virtualexpo_api/getHallWiseGraph/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                visitor_id: user.accountid,
                visitor_type: user.account_type,
                startDate: startDate,
                endDate: endDate
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    setPageWise(res.data[0].DATA.log);
                    setsubmitloader(false);
                    setPageLoader(false)
                }

            }

        }

        getPageWiseGraph();
    }
  return (
    <div>

    <div className="card">
        <div className="card-body">
            {pageloader
                ?
                <Pageloader />
                :
                <div className='graphFilter col-12 text-right'>
                    <a className='cursor-pointer mx-2' onClick={() => { submitpwGraph() }}>  <FontAwesomeIcon icon={faSync} size="1x" /></a>
                    <a className='cursor-pointer' onClick={() => { setpwModalShow(true) }}>  <FontAwesomeIcon icon={faFilter} size="1x" /></a>
                </div>
            }
            <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={PageWise}
                options={options}
            />
        </div>
    </div>
    <div className="pwModalBlock">
        {pwModalShow
            ?
            <Modal show={pwModalShow} onHide={pwModalClose} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>

                <Modal.Body className='col-12 row' >
                    <Form.Group className="col-md-6 mb-3">
                        <Form.Label>Start Date:</Form.Label>
                        <DatePicker value={stDt} dateFormat="DD-MM-YYYY" timeFormat="hh:mm A" onChange={val => setstDt(val)} />
                    </Form.Group>

                    <Form.Group className="col-md-6 mb-3">
                        <Form.Label>End Date:</Form.Label>
                        <DatePicker value={endDt} dateFormat="DD-MM-YYYY" timeFormat="hh:mm A" onChange={val => setendDt(val)} />
                    </Form.Group>
                </Modal.Body>

                <ModalFooter >
                    <Button className='btn btn-secondary' onClick={pwModalClose}>
                        Close
                    </Button>
                    <Button className='btn btn-primary' onClick={() => submitpwGraph()} disabled={submitloader}>
                        Search
                    </Button>
                    <div className="w-100 text-center submitloader mt-3">
                        {submitloader ?
                            <>
                                <Preloader />
                            </>
                            : ""
                        }
                    </div>
                </ModalFooter>

            </Modal>
            : ""
        }

    </div>
</div>
  )
}
