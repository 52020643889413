import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
export const NotFound = ({commonData} ) => {
    const user = useSelector(state => state.userDetails);
  return (
    <div>
  <div className='container-box'>
      <div className="notFound text-center">
      <h1><img   src="/placeholder/404.gif" /></h1>
      <h3>Page Not Found </h3>
      {user.user_type == ""
      ?
      [user.user_type !== 1
        ? <Link to="/dashboard" className='btn btn-primary text-light my-4'>Return To Dashboard</Link>
        : <Link to="/home" className='btn btn-primary text-light my-4'>Return To Home</Link>
      ]
      :
      <Link to="/login" className='btn btn-primary text-light my-4'>Return To Login</Link>
    }
     
      </div>
     
  </div>

    </div>
  )
}
