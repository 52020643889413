import React, { useEffect, useState } from 'react'
import './css/Login2.css'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { PhpToken, UserDetails } from '../Actions';
import { Pageloader } from '../Components/Pageloader';
import { Preloader } from '../Components/Preloader';

export const PanelLoginPage2 = ({ commonData, toast }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const LOGIN_BOTH = "B";
    const LOGIN_CRDENTIAL = "C";
    const LOGIN_OTP = "O";
    const LOGIN_DIRECT = "D";
    document.title = "Login Page";
    // page loaders
    const [pageloader, setPageLoader] = useState(true);
    const [submitloader, setSubmitLoader] = useState(false);
    //end here
    const [otpon, setotpOn] = useState(false);
    const [otpkey, setotpkey] = useState("");
    // store company details
    let compIntitial;
    if (localStorage.getItem("companyinfo") === null) {
        compIntitial = [];
    } else {
        compIntitial = JSON.parse(localStorage.getItem("companyinfo"));
    }
    // end store company details
    // get login page  details
    const [loginMethod, setLoginMethod] = useState("B");
    const [companyInfo, setcompanyInfo] = useState(compIntitial);
    useEffect(() => {
        async function getlogindata() {
            let url = commonData.siteurl + '/expo_access_api/Loginpagedetails/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                TYPE: "A"
            }, headers);
            if (res) {
                if (res.data[0].DATA.login_method) {
                    setLoginMethod(res.data[0].DATA.login_method)
                }

                setPageLoader(false);
                localStorage.setItem("companyinfo", JSON.stringify(res.data[0].DATA.companydetails.Companyinfo));
                setcompanyInfo(res.data[0].DATA.companydetails.Companyinfo);
                if (localStorage.getItem("eeusername")) {
                    setValue("email", localStorage.getItem("eeusername"));
                }
                if (localStorage.getItem("eepassword")) {
                    setValue("password", localStorage.getItem("eepassword"));
                }
            }
        }
        getlogindata();

    }, [loginMethod]);
    // end  get login page  details
    const loginleft = {
        background: "#fff", //"url(/loginleftimage.jpg)",
        minHeight: window.innerHeight,
        backgroundSize: "100% 100%"
    }


    //submit form
    const onSubmit = (data) => {

        setSubmitLoader(true);
        const otpmobile = data.otpmobile;
        if (otpmobile > 0) {
            async function sendotp() {
                let otpurl = commonData.siteurl + '/expo_access_api/checkmobileotp/';
                const headers = {
                    'content-type': 'multipart/form-data'
                };
                const otpres = await axios.post(otpurl, {
                    AUTHORIZEKEY: commonData.AuthorizeKey,
                    mobile: otpmobile
                }, headers);

                if (otpres.data[0].STATUS == "SUCCESS") {
                    setotpkey(otpres.data[0].data);
                    setValue("otpnumbr", "");
                    setotpOn(true);
                    toast("SUCCESS", otpres.data[0].MSG, "success");
                    setSubmitLoader(false);

                } else {
                    toast("Error", otpres.data[0].MSG, "danger");
                    setSubmitLoader(false);
                }
            }
            sendotp();
        } else {
            async function processlogin() {
                const username = data.email;
                const password = data.password;
                const usertype = data.usertype;
                const remember = data.remember_me;
                if (username === "") {
                    toast("Error", "username can not be null", "danger");
                    setSubmitLoader(false);
                    return false;

                }
                if (password === "null" & loginMethod === LOGIN_CRDENTIAL) {
                    toast("Error", "username can not be null", "danger");
                    setSubmitLoader(false);
                    return false;

                }
                let otpurl = commonData.siteurl + '/virtualexpo_api/verifyLogin/';
                const headers = {
                    'content-type': 'multipart/form-data'
                };
                const loginres = await axios.post(otpurl, {
                    AUTHORIZEKEY: commonData.AuthorizeKey,
                    username: username,
                    password: password,
                    usertype: usertype
                }, headers);

                if (loginres.data[0].STATUS == "SUCCESS") {
                    if (remember === true) {
                        localStorage.setItem("eeusername", username);
                        if (password !== "null" & (loginMethod === LOGIN_CRDENTIAL || loginMethod === LOGIN_BOTH)) {
                            localStorage.setItem("eepassword", password);
                        }
                    }
                    toast("SUCCESS", loginres.data[0].MSG, "success");
                    setSubmitLoader(false);
                    if (loginres.data[0].DATA.expo_portal_disallow == "Y") {
                        history.push("/hold");

                    } else {
                        dispatch(PhpToken(loginres.data[0].PHPTOKEN));
                        dispatch(UserDetails(loginres.data[0].DATA));
                        history.push("/dashboard");
                    }

                } else {
                    toast("Error", loginres.data[0].MSG, "danger");
                    setSubmitLoader(false);
                }
            }
            processlogin();
        }


    }

    //submit otp form
    const onotpSubmit = (data) => {
        setSubmitLoader(true);
        async function processlogin() {
            const otpkey = data.otpkey;
            const otpnumber = data.otpnumbr;
            const usertype = data.usertype;
            let otpurl = commonData.siteurl + '/virtualexpo_api/verifyLogin/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const loginres = await axios.post(otpurl, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                otpkey: otpkey,
                otpnumber: otpnumber,
                usertype: usertype
            }, headers);

            if (loginres.data[0].STATUS == "SUCCESS") {
                toast("SUCCESS", loginres.data[0].MSG, "success");
                setSubmitLoader(false);
                if (loginres.data[0].DATA.expo_portal_disallow == "Y") {
                    history.push("/hold");
                } else {
                    dispatch(PhpToken(loginres.data[0].PHPTOKEN));
                    dispatch(UserDetails(loginres.data[0].DATA));
                    history.push("/dashboard");
                }

            } else {
                toast("Error", loginres.data[0].MSG, "danger");
                setSubmitLoader(false);
            }
        }
        processlogin();
    }
    const [CPBlock, setCPBlock] = useState(false)
    const CPCancel = () => {
        setValue("remail", "");
        setCPBlock(false);
    }
    const onCPSubmit = (data) => {
        setSubmitLoader(true);
        async function processCP() {
            const remail = data.remail;
            const usertype = data.usertype;
            let otpurl = commonData.siteurl + '/expo_access_api/changePassword/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const res = await axios.post(otpurl, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                remail: remail,
                usertype: usertype
            }, headers);

            if (res.data[0].STATUS == "SUCCESS") {
                toast("SUCCESS", res.data[0].MSG, "success");
                setSubmitLoader(false);
                setValue("remail", "");
                setCPBlock(false);

            } else {
                toast("Error", res.data[0].MSG, "danger");
                setSubmitLoader(false);
            }
        }
        processCP();
    }
    const d = new Date();
    const year = d.getFullYear();
    const homepage = () =>{
        history.push("/");
    }
    return (
        <div>
            <div className="col-12 login-block2" style={loginleft}>
                <div className="col-12 row login-view ">
                    <div className="col-md-6 leftSide" style={{ background: "url('/images/global/regbg.png')", backgroundSize: 'cover' }} >
                        <div className="col-12 rightBlock text-center">
                        <div className="centerBlock">
                            <img  onClick={()=>{homepage()}} className="imgLogo img-responsive cursor-pointer" src="/images/global/regLogo.png" alt='logo_image' />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 rightSide">
                        <div className="login-form-block2">
                            {CPBlock === false
                                ?
                                <div className="company-name text-center py-3">
                                    <h3 className='fw-bold blueColor'>Welcome  <span className='orangeColor fst-italic'> Exhibitor </span></h3>
                                    <img className='my-3' src="/images/global/user.png" />
                                </div>
                                : ""
                            }
                            {pageloader == true ?
                                <Pageloader />

                                :
                                [CPBlock === false
                                    ?
                                    [otpon === false ?
                                        <Form id="example-form" className="col-12 row m-0" onSubmit={handleSubmit(onSubmit)}>
                                            <Form.Control type="hidden" value="a"  {...register('usertype')} />
                                            <Form.Control type="hidden" value={loginMethod}  {...register('method', { required: "method is required" })} />
                                            {loginMethod == LOGIN_BOTH || loginMethod == LOGIN_CRDENTIAL || loginMethod == LOGIN_DIRECT ?
                                                <Form.Group className="mt-4 col-12" controlId="email">
                                                    <Form.Label>Enter your registered mobile number /  email id:  </Form.Label>
                                                    <Form.Control type="text" placeholder="Please enter your mobile number /  email" {...register('email')} />
                                                    <Form.Text className="text-muted">
                                                        {errors.email && <p className="text-danger">{errors.email.message}</p>}
                                                    </Form.Text>
                                                </Form.Group> : ""
                                            }
                                            {loginMethod == LOGIN_BOTH || loginMethod == LOGIN_CRDENTIAL ?
                                                <Form.Group className="mt-4 col-12" controlId="password">
                                                    <Form.Label> Enter your password:  </Form.Label>
                                                    <Form.Control type="password" placeholder="Please enter your password" {...register('password')} />
                                                    <Form.Text className="text-muted">
                                                        {errors.password && <p className="text-danger">{errors.password.message}</p>}
                                                    </Form.Text>
                                                </Form.Group> : ""
                                            }
                                            {loginMethod == LOGIN_BOTH || loginMethod == LOGIN_CRDENTIAL || loginMethod == LOGIN_DIRECT ?
                                                <Form.Group className="mt-4" controlId="remember_me">
                                                    <Form.Check type="checkbox" label="Remember me" {...register('remember_me')} />
                                                    <a onClick={() => setCPBlock(true)} className='blueColor fw-bold float-right'>Forgot Password</a>
                                                </Form.Group> : ""
                                            }

                                            {loginMethod == LOGIN_BOTH ? <div className="lonin-opt text-center mt-4"><h4 className="blueColor fw-bold">Or login via OTP</h4></div> : ""}

                                            {loginMethod == LOGIN_BOTH || loginMethod == LOGIN_OTP ?
                                                <Form.Group className="col-12 mt-4" controlId="otpmobile">
                                                    <Form.Label> Enter your registered mobile number:  </Form.Label>
                                                    <Form.Control type="number" placeholder="Enter your mobile number to Login" {...register('otpmobile')} />
                                                    <Form.Text className="text-muted">
                                                        {errors.otpmobile && <p className="text-danger">{errors.otpmobile.message}</p>}
                                                    </Form.Text>
                                                </Form.Group> : ""
                                            }
                                            <div className="mt-4 col-12 text-center">
                                                <Button className="btn-lg btnBlue loginBtn" type="submit" disabled={submitloader} >
                                                    Submit
                                                </Button>
                                            </div>
                                            <div className="text-center submitloader mt-3">
                                                {submitloader ?
                                                    <>
                                                        <Preloader />
                                                    </>
                                                    : ""
                                                }
                                            </div>
                                        </Form>
                                        :
                                        <Form id="example-form1" className="col-12 row m-0" onSubmit={handleSubmit(onotpSubmit)}>
                                            <Form.Control type="hidden" value="a"  {...register('usertype')} />
                                            <Form.Control type="hidden" value={otpkey}  {...register('otpkey')} />
                                            <Form.Group className="mt-4 col-12" controlId="otpnumbr">
                                                <Form.Label> Enter your OTP number for verification:  </Form.Label>
                                                <Form.Control type="text" placeholder="please enter your number" {...register('otpnumbr', { required: "Please enter a valid number" })} />
                                                <Form.Text className="text-muted">
                                                    {errors.otpnumbr && <p className="text-danger">{errors.otpnumbr.message}</p>}
                                                </Form.Text>
                                            </Form.Group>
                                            <div className="mt-4 col-12 text-center">
                                                <Button className="btn-lg btnBlue loginBtn" type="submit" disable={submitloader} >
                                                    Submit
                                                </Button>
                                            </div>
                                            <div className="text-center submitloader mt-3">
                                                {submitloader ?
                                                    <>
                                                        <Preloader />
                                                    </>
                                                    : ""
                                                }
                                            </div>
                                        </Form>
                                    ]
                                    :
                                    <div className="forgetPassRow pt-3">
                                        <h4 className='blueColor pb-2 text-center'>Forget your Password?</h4>
                                        <p className='text-center light-grey m-0'>Enter your registered Email address  </p>
                                        <p className='text-center light-grey m-0'>to reset your password</p>
                                        <div className="inpuData py-2">
                                            <Form id="cp-form1" className="col-12 row m-0 py-4" onSubmit={handleSubmit(onCPSubmit)}>
                                                <Form.Group className="col-12" controlId="remail">
                                                    <Form.Label>Enter your register email or mobile number:  </Form.Label>
                                                    <Form.Control type="text"  {...register('remail', { required: "Required" })} />
                                                    <Form.Text className="text-muted">
                                                        {errors.remail && <p className="text-danger">{errors.remail.message}</p>}
                                                    </Form.Text>
                                                </Form.Group>
                                                <div className="my-4 col-12 text-center">
                                                    <Button onClick={() => CPCancel()} className="btn-lg outline-secondary loginBtn mx-2" variant="outline-secondary" disable={submitloader} >
                                                        Cancel
                                                    </Button>
                                                    <Button className="btn-lg btnBlue loginBtn" variant="primary" type='submit' disable={submitloader} >
                                                        Reset
                                                    </Button>
                                                </div>
                                                <div className="text-center submitloader mt-3">
                                                    {submitloader ?
                                                        <>
                                                            <Preloader />
                                                        </>
                                                        : ""
                                                    }
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                ]
                            }
                            <p className="text-center pb-5"></p>

                        </div>
                    </div>
                </div>

                </div>
                <footer className="">
                <div className="col-12 row">
                <div className="col-12 text-center">
                © {year} All rights reserved by bharat expo.
                    </div>
                </div>
                
            </footer>

            
        </div>
    )
}
