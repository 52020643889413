let initial;
if(localStorage.getItem("phptoken")  === null ){
    initial = null;
}else{
    initial = localStorage.getItem("phptoken");
}


const phpToken = (state= initial , action ) =>{
    if(action.type === "PHPTOKEN" ){
        localStorage.setItem("phptoken",  action.payload ); 
        return action.payload;
    }else{
        return state;
    }
   
}

export default phpToken;