import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pageloader } from '../Components/Pageloader';
import { useSelector } from 'react-redux';

export const LatestInquiry = ({commonData}) => {
    const phptoken = useSelector(state => state.phpToken);
    const user = useSelector(state => state.userDetails);

    const [pageloader, setPageLoader] = useState(false);
    const [refresh, setrefresh] = useState("");
    const [Lead, setLead] = useState("");
    useEffect(() => {
        async function getlead() {
            setPageLoader(true);
            let url = commonData.siteurl + '/virtualexpo_api/getlatestleads/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                visitor_id: user.accountid,
                visitor_type: user.account_type,
                stallid: user.stall_id
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    setLead(res.data[0].DATA.lead);
                    setPageLoader(false);
                }

            }

        }

        getlead();
    }, [refresh]);
    return (
        <div className='latest-lead-view'>
                    <div className="card">
                        <div className="header-titles">
                        <h4 className="card-title font-bold">Latest inquiry list </h4>
                        <h6 className="card-subtitle">List of latest 5 inquiries.</h6>
                        </div>
                        <div className="card-body">
                            {pageloader
                            ? <Pageloader/>
                            :
                            [Lead
                            ? 
                            <div className="leadlist-view">
                                {Lead.map((t) => (  
                            
                                <div key={`llv_${t.ei_id}`} className="col-12 row llv-row mb-4">
                                   <div className='col-2'>
                                   <img className="img-responsive roundedCircle50" src={`${commonData.siteurl}/viewimage/getcontactforApp/${t.photo_path}`} /> 
                                   </div>
                                   <div className='col-10'>
                                    <h5>{t.name}</h5>
                                    {t.designation ? <h6>{t.designation}</h6>  : ""}
                                    {t.company ? <h6>{t.company}</h6>  : ""}
                                    <hr/>
                                   <h6> Date: {t.created_dt}</h6>
                                   <h6> Mobile: {t.mobile_no}</h6>
                                   <h6> Email: {t.email_address}</h6>
                                   <h6> Assigned to: {t.assignee}</h6>
                                   {
                                       t.productname
                                       ?
                                       <>
                                       <hr/>
                                        <h6>Product: {t.productname}</h6>
                                        <h6>Quantity:  {t.qty}</h6>
                                        <h6>Remark:  {t.remarks}</h6>
                                       </>
                                       : ""
                                   }
                                   </div>
                                   <hr/>
                                    </div>
                                   
                                ))}
                        </div>
                            : "Did not found any latest inquiry."
                            ]
                            }
                           
                        </div>
                    </div>
        </div>
    )
}
