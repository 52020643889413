import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Pageloader } from '../Components/Pageloader';
import { Exe_Dashboard } from '../Layouts/Exe_Dashboard';
import { Org_Dashboard } from '../Layouts/Org_Dashboard';

export const Dashboard = ({commonData}) => {
    document.title = "Dashboard";
const [DashboardOrg, setDashboardOrg] = useState(false);
const [pageloader, setPageLoader] = useState(true);
const user = useSelector(state => state.userDetails);  

    useEffect(() => {
        if(user){
            if(user.user_type == commonData.Organiser){
                setDashboardOrg(true);
                setPageLoader(false);
            }else{
                setDashboardOrg(false);
                setPageLoader(false);
            }
        }
        
    },[user]);

    return (
        <div style={commonData.minHeight}>

            {pageloader
            ?  <Pageloader />
            :
            [DashboardOrg
            ? <Org_Dashboard commonData={commonData} />
            : <Exe_Dashboard commonData={commonData} />
            ]
        
        }
       
        </div>
    )
}
