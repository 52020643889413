let initial = false;


const initials = initial;
const AuditoriumVideoPlay = (state= initials , action ) =>{
    if(action.type === "AUDITORIUMVIDEOPLAY" ){
        return action.payload;
    }else{
        return state;
    }
   
}

export default AuditoriumVideoPlay;