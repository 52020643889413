import React from 'react'
import { OrgAgendaWise } from '../Graphs/OrgAgendaWise'
import { OrgHallWise } from '../Graphs/OrgHallWise'
import { OrgPageWise } from '../Graphs/OrgPageWise'
import { OrgStallWise } from '../Graphs/OrgStallWise'

const Analytics = ({ commonData,phptoken,user }) => {
  return (
    <div>
      <div className="col-12 row">
          <div className="col-md-6 my-2">
            <OrgPageWise commonData={commonData} phptoken={phptoken} user={user} />
          </div>
          <div className="col-md-6 my-2">
            <OrgStallWise commonData={commonData} phptoken={phptoken} user={user} />
          </div>
          <div className="col-md-6 my-2">
            <OrgHallWise commonData={commonData} phptoken={phptoken} user={user} />
          </div>
          <div className="col-md-6 my-2">
            <OrgAgendaWise commonData={commonData} phptoken={phptoken} user={user} />
          </div>
      </div>
    </div>
  )
}

export default Analytics
