let initial = false;


const initials = initial;
const HelpDeskVideoPlay = (state= initials , action ) =>{
    if(action.type === "HELPDESKVIDEOPLAY" ){
        return action.payload;
    }else{
        return state;
    }
   
}

export default HelpDeskVideoPlay;