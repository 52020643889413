import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Pageloader } from '../Components/Pageloader';

export const RecentContact = ({ commonData }) => {
    const phptoken = useSelector(state => state.phpToken);
    const user = useSelector(state => state.userDetails);
    //get recent contact detals
    const [RecentCon, setRecentCon] = useState([]);
    const [pageloader, setPageLoader] = useState(false);
    useEffect(() => {
        async function gettask() {
            setPageLoader(true);
            let url = commonData.siteurl + '/virtualexpo_api/getrecentcontact/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                visitor_id: user.accountid,
                visitor_type: user.account_type
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    setRecentCon(res.data[0].DATA.contact);
                    setPageLoader(false);
                }

            }

        }

        gettask();
    }, []);
    return (
        <div className="Recentcon-view">
            <div className="card">
                <div className="header-titles">
                    <h4 className="card-title font-bold">Recent Contact </h4>
                    <h6 className="card-subtitle">List of recent 5 contact which had communicated.</h6>
                </div>
                <div className="card-body">
                    {pageloader
                        ? <Pageloader />
                        :
                        [RecentCon
                            ?
                            [RecentCon.map((resc) => (
                                <div key={`visitor-contact_${resc.visitor_id}`} className="col-12 row mb-4">
                                <div className="col-md-2">
                                    <img className="img-responsive roundedCircle50" src={`${commonData.siteurl}/viewimage/getcontactforApp/${resc.visitorphoto}`} />
                                </div>
                                <div className="col-md-10">
                                    <h5>{resc.visitorname}</h5>
                                    <h6>{resc.visitor_designation}</h6>
                                    <h6>{resc.visitor_comp}</h6>
                                    <p className="mb-0">Last Visited: {resc.last_visit} ({resc.timeago})</p>
                                    <p className="mb-0">Visited Times: {resc.visited_times}</p>
                                    <p className="mb-0">Visited:  {resc.assignname}</p>
                                    <p className="mb-0">Mobile: {resc.mobile_no}</p>
                                    <p className="mb-0">Email: {resc.email_address}</p>
                                </div>
                            </div>
                            ))]
                     
                        : "Did not found any recent contact."
                        ]
                    }
                </div>
            </div>
        </div>
    )
}
