let initial;
if(localStorage.getItem("productid")  === null ){
    initial = null;
}else{
    initial = localStorage.getItem("productid");
}


const ProductDetailId = (state= initial , action ) =>{
    if(action.type === "PRODUCTDETAILID" ){
        localStorage.setItem("productid",  action.payload ); 
        return action.payload;
    }else{
        return state;
    }
   
}

export default ProductDetailId;