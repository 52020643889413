import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pageloader } from '../Components/Pageloader';
import { useSelector } from 'react-redux';
import { Modal, Button, Form, ModalFooter } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import {ToastNotify} from '../Actions';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { Preloader } from '../Components/Preloader';

export const Task = ({commonData , Associate}) => {
    const phptoken = useSelector(state => state.phpToken);
    const user = useSelector(state => state.userDetails);
    const { register, handleSubmit,reset, formState: { errors } } = useForm();

    const [pageloader, setPageLoader] = useState(false);
    const [refresh, setrefresh] = useState("");
    const [Task, setTask] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        async function gettask() {
            setPageLoader(true);
            let url = commonData.siteurl + '/virtualexpo_api/gettask/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                visitor_id: user.accountid,
                visitor_type: user.account_type
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    setTask(res.data[0].DATA.task);
                    setPageLoader(false);
                }

            }

        }

        gettask();
    }, [refresh]);

    //change status
  const  changestatus = (eot_id)=>{
     // console.log(eot_id);
      async function savetaskstatus() {
       
        let url = commonData.siteurl + '/virtualexpo_api/savetaskstatus/';

        const res = await axios.post(url, {
            AUTHORIZEKEY: commonData.AuthorizeKey,
            PHPTOKEN: phptoken,
            eot_id: eot_id
        });

        if (res) {
            if (res.data[0].STATUS == "SUCCESS") {
                dispatch(ToastNotify(true,"SUCCESS", res.data[0].MSG, "success"));
                document.getElementById("task_"+eot_id).disabled = true;
                
            }

        }

    }

    savetaskstatus();

    }

    //send task data
    const [submitloader, setSubmitLoader] = useState(false);
    const [TaskShow, setTaskShow] = useState(false);
    const TaskClose = () => {
        setTaskShow(false);
    }
    const onTaskSubmit = (data) => {
        async function submitTask() {
           
                let url = commonData.siteurl + '/virtualexpo_api/saveTask/';
                const headers = {
                   'content-type': 'multipart/form-data'
               };
               
               const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                taskassignee: data.taskassignee,
                taskname: data.taskname,
                taskremark: data.taskremark,
                visitor_name: user.name,
                visitor_id: user.accountid,
                visitor_type: user.account_type
            });
               
                if (res.data[0].STATUS == "SUCCESS") {
                 setrefresh(Math.random() * 1000);
                   TaskClose();
                   dispatch(ToastNotify(true,"SUCCESS", res.data[0].MSG, "success"));
                   reset();
                    setSubmitLoader(false);
                   
                } else {
                   dispatch(ToastNotify(true,"Error", res.data[0].MSG, "danger"));
                    setSubmitLoader(false);
                }
                
            }
            submitTask();
    }

    return (
        <div className="task-view">
             <div className="card">
                        <div className="header-titles">
                        <h4 className="card-title font-bold">Task list <Button onClick={()=>{setTaskShow(true)}} className="btn btn-sm btn-primary float-right">Add</Button></h4>
                        <h6 className="card-subtitle">List of your next task to complete</h6>
                        </div>
                        <div className="card-body">
                            {pageloader
                            ? <Pageloader/>
                            :
                            [Task
                            ? 
                            <div className="tasklist-view">
                                {Task.map((t) => (  
                                <div key={`task_${t.eot_id}`} className="col-12 row task-row mb-4">
                                <div className="col-1 task-check">
                                    {t.status_flag == "Y"
                                    ?
                                    <Form.Check type="checkbox" defaultChecked  />
                                    :
                                    [t.assign_id !== user.accountid 
                                    ?<Form.Check id={`task_${t.eot_id}`}  type="checkbox" disabled  />
                                    :<Form.Check id={`task_${t.eot_id}`} onClick={()=>{changestatus(t.eot_id)}} type="checkbox"   />
                                    ]
                                    }
                                
                                </div>
                                <div className="col-11 task-details">
                                    <h5>{t.name} ({t.task_no}) </h5>
                                    <h6>Created On: {t.created_dt} </h6>
                                    <h6>Remark: {t.remark}</h6>
                                    {user.accountid == t.assign_id && user.accountid == t.created_by
                                    ? ""
                                    :
                                    <p>
                                        <img className="img-responsive roundedCircle50" src={`${commonData.siteurl}/viewimage/getcontactforApp/${t.creatorphoto}`} /> <span className="text-danger">{t.creatorname}   </span> <FontAwesomeIcon className="arrow-sign"  icon={faArrowRight} size="3x" />   <img className="img-responsive roundedCircle50" src={`${commonData.siteurl}/viewimage/getcontactforApp/${t.assignphoto}`} /> {t.assignname}  
                                    </p>
                                    }
                                    
                                </div>
                                    </div>
                                ))}
                        </div>
                            : "Did not found any task."
                            ]
                            }
                           
                        </div>
                    </div>
                    <Modal show={TaskShow} onHide={TaskClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Task </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form id="task-form" className="col-12 row" onSubmit={handleSubmit(onTaskSubmit)}>
                    <Form.Group className="mb-3" controlId="taskname">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Enter Task Name" {...register('taskname', { required: true })} />
                        <Form.Text className="text-danger">
                        {errors.taskname && <span>This field is required</span>}
                        </Form.Text>
                    </Form.Group>
                 
                   
                        <Form.Group className="mb-3" controlId="taskassignee">
                            <Form.Label>Select stall</Form.Label>
                            <Form.Select aria-label="Default select example" {...register('taskassignee' , { required: true })}>
                            <option value="">Open this select assignee</option>
                                {Associate ?
                                    [Associate.map((asso) => (
                                        <option key={`asso_${asso[""].subordinate_id}`} value={asso[""].subordinate_id}>{asso[""].name}</option>
                                    ))] : ""}
                            </Form.Select>
                            <Form.Text className="text-danger">
                            {errors.taskassignee && <span>This field is required</span>}
                            </Form.Text>
                        </Form.Group>
                       
                        <Form.Group className="mb-3" controlId="taskremark">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} {...register('taskremark', { required: true })} />
                            <Form.Text className="text-danger">
                            {errors.taskremark && <span>This field is required</span>}
                            </Form.Text>
                        </Form.Group>
                       
                     <ModalFooter>
                    <Button variant="secondary" onClick={TaskClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit" disabled={submitloader}>
                        Save 
                    </Button>
                    <div className="w-100 text-center submitloader mt-3">
                                    {submitloader ? 
                                    <>
                                   <Preloader/>
                                  </>
                                : ""    
                                }
                                </div>
              </ModalFooter>
                    </Form>
                </Modal.Body>
            
            </Modal>
        </div>
    )
}
