let initial;
if(localStorage.getItem("userdetails")  === null ){
    initial = {"isLoggedIn": "N"};
}else{
    initial = JSON.parse(localStorage.getItem("userdetails"));
}

const initials = initial;
const userDetails = (state= initials , action ) =>{
    if(action.type === "USERDETAILS" ){
        localStorage.setItem("userdetails",   JSON.stringify(action.payload) ); 
        return action.payload;
    }else{
        return state;
    }
   
}

export default userDetails;