import React from 'react'
// import { LoginPage } from '../Layouts/LoginPage'
// import { LoginPage1 } from '../Layouts/LoginPage1';
// import { MobileLoginPage } from '../Layouts/MobileLoginPage';
// import { LoginPage } from '../Layouts/Mobile/LoginPage';
import DeviceIdentifier from 'react-device-identifier';
import { LoginPage2 } from '../Layouts/LoginPage2';
import { Loginpage1 } from '../Layouts/Mobile/Loginpage1';

export const Login = ({commonData, toast}) => {

    return (
        <div>
          
          <DeviceIdentifier isMobile={true} >
          <Loginpage1 commonData={commonData} toast={toast}/>
          </DeviceIdentifier>

          <DeviceIdentifier isDesktop={true} isTablet={true}>
          <LoginPage2 commonData={commonData} toast={toast}/>
          </DeviceIdentifier>
       </div>
    )
}
