import React from 'react'
import { Footer } from './Footer';
import { Header } from './Header';
export const ExhiTermCondition = () => {
    document.title = "Exhibitor Terms Policy Page";
    return (
        <div>
            <Header />
            <div className="aboutWB bg-white">
            <div className="image-bg-slider1" style={{background:"url(/images/website/Contact_us_Banner.png)"}}>
                <div className="business-platform">
                    <h4>Terms & Conditions</h4>
                </div>
        </div>

        <div className="WB-Privacy">
            <div className="privacy-para1">
 <p>Please read these terms and conditions carefully before using Our platform.</p>
 <h4>Interpretation</h4>
 <p>The words in which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.
</p>
</div>
<div className="terms-list">
<ul>
    <li>Taxes will be applicable (if not quoted) </li>
<li>Payment will be 100% in advance.</li>
<li>Stall once booked will not be canceled.</li>
<li>Bharat Expo is not bound to make any refund. </li>
<li>Stall position and domes are subject matter to change. We are not liable if there is any change in your stall and dome. </li>
<li>In case of any dispute between the visitor and exhibitor, Bharat Expo is not responsible.</li>
<li>The Site may provide facilities that enable you to communicate with other users of the Site.  If you communicate with any other user of the Site, you must ensure that to the best of your knowledge and belief, any such communication is truthful and accurate in all respects; no such communication shall be inappropriate, abusive, or objectionable; and if applicable, you shall immediately comply with any request from any other user of the Site to stop communicating with them.
</li>
<li>If there are not enough participants after initiating the event, Bharat Expo has the right to cancel the event and after initiating the event Bharat Expo is liable to extend your package for one month.  </li>
<li>Video shooting is only possible in the cities where there are local video partners. We agree to edit your video if you provide us with the footage. </li>
<li>Physical events are only organized if there are more than 50 participants. </li>
<li>Stalls design and colours are limited. Please select as per the availability. </li>
<li>We do not guarantee that the Event Platform will operate continuously, securely, or without errors or interruption, and we do not accept any liability for its temporary unavailability. </li>
<li>Access to the Site is provided temporarily and we reserve the right to withdraw access to the Site or amend any service we provide on the Site without notice.  We will not be liable if, for any reason, the Site is unavailable at any time or for any period.
</li>
<li>You are responsible for making all arrangements necessary for you to have access to the Site.  You are also responsible for ensuring that all persons who access the Site through your Internet connection are aware of these Terms and that they comply with them.  You agree not to damage, interfere with or disrupt access to the Site, or do anything which may interrupt or impair its functionality. </li>
<li>Anything on the Site may be out of date at any given time and we are under no obligation to update it.  We seek to ensure that information published on the Site is accurate when posted, but we cannot be held liable for its accuracy or timeliness and we may change the information at any time without notice.  You must not rely on the information on the Site and you acknowledge that you must take appropriate steps to verify any such information before acting upon it.
</li>
<li>We reserve the right to monitor and track your visits to the Site.</li>
<li>We do not guarantee that the Event Platform and/or any content thereon (including, without limitation, any content available for download) will be free from viruses, infections, Trojan horses, worms, and/or any other code that has contaminating or destructive properties. </li>
<li>You must not attempt to interfere with the proper working of the Event Platform (for example, by attempting to circumvent security or tamper with, hack into or otherwise disrupt any computer system, server, website, router, or any other internet-connected device). </li>
</ul>
        </div>
        <div className="privacy-para1">
            <h4>Acknowledgment</h4>
            <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
</p>
            <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Service.
</p>
            <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.
</p>
            </div>
            <div className="privacy-para1">
            <h4>Intellectual Property</h4>
            <p>The Service and its original content, features, and functionality are and will remain the exclusive property of Bharat Expo and its licensors. The Service is protected by copyright, trademark, and other laws. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Bharat Expo. 
</p>
            </div>
            <div className="privacy-para1">
            <h4>Changes to These Terms and Conditions</h4>
            <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. What constitutes a material change will be determined at Our sole discretion.
</p>
            <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
</p>
            <p>The personal data you provide will be used to register you for an event. Registrations from non-preferred media partners and their associates will be canceled and refunded without prior arrangement and consent.
</p>
            </div>
        </div>
            </div>
            <Footer />
        </div>
    )
}
