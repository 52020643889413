let initial;

    initial = { stall: ""};


const initials = initial;
const CurrentStall = (state= initials , action ) =>{
    if(action.type === "CURRENT" ){
        return {stall:action.stall};
    }else{ 
        return state;
    }
   
}

export default CurrentStall;