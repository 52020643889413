let initial;
    initial = { on: false,  amount: "" , currency: "", contactid: "", contacttype: "",name: "" ,number: "" ,email: "" , mode: "" ,site: "",redirect: ""} ;
const initials = initial;
const PaymentBox = (state= initials , action ) =>{
    if(action.type === "PAYMENTS" ){
        return {on:action.on, amount:action.amount , currency:action.currency, contactid:action.contactid, contacttype: action.contacttype,name: action.name,number: action.number,email: action.email, mode: action.mode, site: action.site, redirect: action.redirect};
    }else{ 
        return state;
    }
   
}

export default PaymentBox;