let initial;

    initial = { num: "" };


const initials = initial;
const RefVisits = (state= initials , action ) =>{
    if(action.type === "REFRESHVISIT" ){
        return {num:action.num };
    }else{ 
        return state;
    }
   
}

export default RefVisits;