let initial;
initial = {"on": false, id: "", name: "", receiver:"", caller:"", stallid: "" };

const initials = initial;
const userDetails = (state= initials , action ) =>{
    if(action.type === "CALLING" ){
        return {on:action.on, id:action.id , name: action.name , receiver: action.receiver , caller: action.caller, stallid: action.stallid};
    }else{
        return state;
    }
   
}

export default userDetails;