import React, {useEffect} from 'react'
import { Footer } from './Footer';
import { Header } from './Header';

export const TermCondition = () => {
    document.title = "Visitor Terms Policy Page";
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <Header />
            <div className="aboutWB bg-white">
                <div className="image-bg-slider1" style={{ background: "url(/images/website/Contact_us_Banner.png)" }}>
                    <div className="business-platform">
                        <h4>Terms & Conditions</h4>
                    </div>
                </div>

                <div className="WB-Privacy">
                    <div className="privacy-para1">

                        <p>These terms and conditions apply to visitor registrations for, and attendance at, events (each an “Event”) organized by Bharat Expo (referred to as “we/our/us”)</p>
                        <p>As a potential visitor to an Event (referred to as “you/your”), please read these terms and conditions carefully as they contain important information.  By submitting your registration and/or by attending an Event, you agree to be bound by these terms and conditions.
                        </p>
                    </div>
                    <div className="terms-list">
                        <ul>
                            <li>Your registration (whether submitted directly by you or on your behalf) constitutes an offer by you to Bharat Expo to attend the Event in accordance with these terms and conditions.  All registrations are subject to acceptance by Bharat Expo.  If your registration is received in advance of the event, it will either: (a) be confirmed in writing (including, without limitation, by email), or (b) if written confirmation is not sent within fourteen (14) days of us receiving your registration, be deemed to be confirmed unless we notify you otherwise.  If your registration is received only at the event itself, it will be confirmed by the act of Bharat Expo permitting you entrance to the Event.  Bharat Expo reserves the right to refuse to accept any registration.
                            </li>
                            <li>Your registration is issued for your personal use only.  You may not sell or otherwise transfer your registration to others.</li>
                            <li>Bharat Expo reserves the right at any time to change the format, content, location, venue, opening hours, duration, dates, other timings, participants, and/or any other aspect of the Event, in each case without liability. Bharat Expo reserves the right to withdraw any registration after confirmation without liability.
                            </li>
                            <li>Save as expressly set out in these terms and conditions and/or as may be otherwise expressly advised as part of the particular Event’s registration process: (a) your registration for the Event is irrevocable and you have no rights to cancel your registration, and (b) any registration fees you have paid will not be refunded for any reason.
                            </li>
                            <li>Bharat Expo reserves the right without liability to refuse admission to, or to eject/block from, the Event any person in its absolute discretion, including (without limitation) any person who fails to comply with these terms and conditions or who, in the opinion of Bharat Expo, represents a security risk, nuisance or annoyance to the running of the Event.  You agree to comply with all reasonable instructions issued by Bharat Expo, the venue owners, security personnel, and/or management at the Event.
                            </li>
                            <li>The personal information that you provide Bharat Expo to will be held by us on a database and, where we have appropriate authorisation/justification (which may include, without limitation, express/implied consent or legitimate interests), may be shared with other entities within the Bharat Expo and selected third parties internationally for promotional and other purposes.  Your personal information may also be shared with government entities and regulatory authorities as required by law.  At some Events, exhibitors and sponsors may use lead capture applications or barcode scanner devices.  If you choose to allow your badge (whether physical or digital) to be scanned by third parties at the event, we may pass your personal information to such third parties.  Further, there may be certain areas of the Event at which your attendance is conditional on your personal information being provided to third parties that have sponsored or are managing such areas.  The use that any third parties make of your personal information is outside of Bharat Expo’s control and, to the extent permitted by applicable law, we do not accept any liability in this regard. </li>
                            <li>Bharat Expo reserves the right to amend these terms and conditions (without notice) from time to time and you will be subject to the terms and conditions in force at the time that the event is staged.</li>
                        </ul>
                    </div>

                </div>
            </div>
            <Footer/>
    </div>
    )
}
