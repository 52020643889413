import React, { useState, useEffect } from 'react';
import './css/Org_Dashboard.css'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Pageloader } from '../Components/Pageloader';
import 'font-awesome/css/font-awesome.min.css';
import { ExeTask } from '../Elements/ExeTask';
import { LatestInquiry } from '../Elements/LatestInquiry';
import ExeLiveVisits from '../Elements/ExeLiveVisits';


export const Exe_Dashboard = ({ commonData }) => {
  const phptoken = useSelector(state => state.phpToken);
  const user = useSelector(state => state.userDetails);

  const [TotalVisitor, setTotalVisitor] = useState("");
  const [TotalLead, setTotalLead] = useState("");
  const [TotalInterest, setTotalInterest] = useState("");
  const [TotalComunication, setTotalComunication] = useState("");
  const [Associate, setAssociate] = useState([]);
  const [pageloader, setPageLoader] = useState(false);
  useEffect(() => {
    async function getexe_dasboarddetails() {
      setPageLoader(true);
      let url = commonData.siteurl + '/virtualexpo_api/getexe_dasboarddetails/';

      const res = await axios.post(url, {
        AUTHORIZEKEY: commonData.AuthorizeKey,
        PHPTOKEN: phptoken,
        visitor_id: user.accountid,
        visitor_type: user.account_type,
        stall_id: user.stall_id
      });

      if (res) {
        if (res.data[0].STATUS == "SUCCESS") {
          setTotalVisitor(res.data[0].DATA.totalvisitor);
          setTotalComunication(res.data[0].DATA.totalcommunication);
          setTotalLead(res.data[0].DATA.totalead);
          setTotalInterest(res.data[0].DATA.totalinterested);
          setAssociate(res.data[0].DATA.assocontact);

          setPageLoader(false);
        }

      }

    }

    getexe_dasboarddetails();
  }, []);
  return (
    <div className='container-xxl exe-dashboard pt-5'>
      <div className="col-12 row mx-0 topbar">

        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <div className="icon-place">
                <img src={`/stall/icon/Icon-1.png`} />
                <span className='dashHeadTitle'>Total Visitors</span>
              </div>
              <h4 className="count-place">
                {pageloader
                  ? <Pageloader />
                  : [TotalVisitor]
                }

              </h4>
              <h6 className="name-place">
                <mute>These are the no's of Visitors who visited </mute>
              </h6>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <div className="icon-place">
                <img src={`/stall/icon/Icon-2.png`} />
                <span className='dashHeadTitle'> Information Collector</span>
              </div>
              <h4 className="count-place">
                {pageloader
                  ? <Pageloader />
                  : [TotalInterest]
                }
              </h4>
              <h6 className="name-place">
                <mute>These are the "Total no's of Visitors"  who gather "product information" from our stall using"Briefcase". </mute>
              </h6>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <div className="icon-place">
                <img src={`/stall/icon/Icon-3.png`} />
                <span className='dashHeadTitle'>Total Interaction</span>
              </div>
              <h4 className="count-place">
                {pageloader
                  ? <Pageloader />
                  : [TotalComunication]
                }
              </h4>
              <h6 className="name-place">
                <mute>These are the no's of Visitors who made interaction with me </mute>
              </h6>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <div className="icon-place">
                <img src={`/stall/icon/Icon-4.png`} />
                <span className='dashHeadTitle'>My Inquiry</span>
              </div>
              <h4 className="count-place">
                {pageloader
                  ? <Pageloader />
                  : [TotalLead]
                }
              </h4>
              <h6 className="name-place">
                <mute>These are the no's of Visitors who made "Inquiry" with or without a selection of products at my stall by keeping me in reference </mute>
              </h6>
            </div>
          </div>
        </div>

      </div>
      <div className="col-12 row mx-0 my-5 bottombar">
        <div className="col-md-12 mt-4">
          <ExeLiveVisits commonData={commonData}/>
        </div>
        <div className="col-md-6 mt-4">
          <ExeTask commonData={commonData} Associate={Associate} />
        </div>
        <div className="col-md-6 mt-4">
          <LatestInquiry commonData={commonData} />
        </div>


      </div>
    </div>
  )
}
