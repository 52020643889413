import React from 'react'
import { useParams } from 'react-router';
import { ExeRegisterPage } from '../Layouts/ExeRegisterPage';
const ExeRegister = ({commonData,toast}) => {
    const { name } = useParams();
  return (
    <div>
      <ExeRegisterPage name={name} commonData={commonData} toast={toast} />
    </div>
  )
}

export default ExeRegister
