let initial;

    initial = { on: false};


const initials = initial;
const VisitorSounds = (state= initials , action ) =>{
    if(action.type === "VISITORSOUND" ){
        return {on:action.on};
    }else{ 
        return state;
    }
   
}

export default VisitorSounds;