let initial = false;


const initials = initial;
const ExhibitionVideoPlay = (state= initials , action ) =>{
    if(action.type === "EXHIBITIONVIDEOPLAY" ){
        return action.payload;
    }else{
        return state;
    }
   
}

export default ExhibitionVideoPlay;