import React from 'react'

export const Preloader = () => {
    return (

        <div className="text-center mt-3">
            <div className="spinner-grow preloaderCOLOR1" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow preloaderCOLOR2" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow preloaderCOLOR3" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow preloaderCOLOR4" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow preloaderCOLOR5" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow preloaderCOLOR6" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow preloaderCOLOR7" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>

    )
}
