import axios from 'axios'
import { Store } from 'react-notifications-component';
import CryptoJS from 'crypto-js';
export const trackdata = (siteurl, AuthorizeKey, phptoken, page, place, order, contact, video) => {

    async function sendTrack() {
        let url = siteurl + '/virtualexpo_api/dranzer/';
        const headers = {
            'content-type': 'multipart/form-data'
        };
        const res = await axios.post(url, {
            AUTHORIZEKEY: AuthorizeKey,
            PHPTOKEN: phptoken,
            page: page,
            place: place,
            order: order,
            contact: contact,
            video: video
        }, headers);
        if (res) {
            if (res.data[0].DATA) {
                console.log(res.data[0].DATA);
            }

        }
    }
    sendTrack()
};

export const videonotify = (siteurl, AuthorizeKey, phptoken, account, account_type, receiver, receiver_type, msg, msg_type, urls) => {

    async function sendTrack() {
        let url = siteurl + '/virtualexpo_api/notify/';
        const headers = {
            'content-type': 'multipart/form-data'
        };
        const res = await axios.post(url, {
            AUTHORIZEKEY: AuthorizeKey,
            PHPTOKEN: phptoken,
            account: account,
            account_type: account_type,
            receiver: receiver,
            receiver_type: receiver_type,
            msg: msg,
            msg_type: msg_type,
            url: urls
        }, headers);
        if (res) {
            if (res.data[0].DATA) {
                localStorage.setItem("lastnotify", res.data[0].DATA);
            }

        }
    }
    sendTrack()
};

export const defaultNotify = (title, notify, type, container, commonData, phptoken, user, dispatch, NotifyVideo, history, Location) => {
    var notifyid = notify.notify_id
    Store.addNotification({
        title: title,
        message: notify.msg,
        type: type,
        insert: "top",
        container: container,
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        onRemoval: (id, removedBy) => {
            checknotify(commonData, notify, removedBy, phptoken, user, dispatch, NotifyVideo, history, Location)
        },
        dismiss: {
            duration: 5000,
            onScreen: true,
            pauseOnHover: true
        }
    });

    const checknotify = (commonData, notify, removedBy, phptoken, user, dispatch, NotifyVideo, history, Location) => {
        if (removedBy == "click") {
            if (notify.msg_type == commonData.NOTIFY_FEEDBACK) {

            } else if (notify.msg_type == commonData.NOTIFY_INQUIRY) {
                localStorage.setItem("active_management_tab", "lead-tab");
                if (Location.pathname == "/stallmanagement") {
                    document.getElementById("uncontrolled-tab-example-tab-lead-tab").click()
                } else {
                    history.push('/stallmanagement');
                }


            }
            else if (notify.msg_type == commonData.NOTIFY_VIDEOCALL) {
                async function opennotification() {

                    let url = commonData.siteurl + '/virtualexpo_api/getnotifydetail/';

                    const res = await axios.post(url, {
                        AUTHORIZEKEY: commonData.AuthorizeKey,
                        PHPTOKEN: phptoken,
                        visitor_id: notify.account_id,
                        visitor_type: user.account_type
                    });

                    if (res) {
                        if (res.data[0].STATUS == "SUCCESS") {
                            if (res.data[0].DATA) {
                                if (res.data[0].DATA.contact) {
                                    dispatch(NotifyVideo(true, notify.notify_id, notify.account_id, res.data[0].DATA.contact.Contact.name, res.data[0].DATA.contact.Contact.photo_path, notify.url))
                                }

                            }
                        }
                    }
                }
                if(notify.url){
                    opennotification();
                }
               

            }
            else if (notify.msg_type == commonData.NOTIFY_TASK) {
                history.push('/dashboard');
            }
            else if (notify.msg_type == commonData.NOTIFY_APPOINTMENT) {
                if (user.user_type == commonData.Agent) {
                    localStorage.setItem("active_management_tab", "appointment-tab");
                    if (Location.pathname == "/stallmanagement") {
                        document.getElementById("uncontrolled-tab-example-tab-appointment-tab").click()
                    } else {
                        history.push('/stallmanagement');
                    }
                } else {
                    localStorage.setItem("active_brief_tab", "appointment");
                    if (Location.pathname == "/briefcase") {
                        document.getElementById("uncontrolled-tab-example-tab-appointment").click()
                    } else {
                        history.push('/briefcase');
                    }
                }
            } else {
                if (user.user_type == commonData.Organiser) {
                    localStorage.setItem("active_detail_tab", "MyVisitor");
                    history.push('/details');
                    if (Location.pathname == "/details") {
                        document.getElementById("uncontrolled-tab-example-tab-MyVisitor").click()
                    } else {
                        history.push('/details');
                    }
                } else if (user.user_type == commonData.Agent) {
                    localStorage.setItem("active_management_tab", "visitor-tab");
                    if (Location.pathname == "/stallmanagement") {
                        document.getElementById("uncontrolled-tab-example-tab-visitor-tab").click()
                    } else {
                        history.push('/stallmanagement');
                    }

                }
            }
        }

        async function readNotify() {
            let url = commonData.siteurl + '/virtualexpo_api/readNotify/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                notify: notifyid
            }, headers);
            if (res) {
                if (res.data[0].MSG) {
                    console.log(res.data[0].MSG);
                }

            }
        }
        if (notifyid) {
            readNotify()
        }

    }
};

export const decoder = (name) => {
    var words = CryptoJS.enc.Base64.parse(name);
    var textString = CryptoJS.enc.Utf8.stringify(words);
    const myArray = textString.split("&")
    if (myArray[0]) {
        var lastcont = myArray[0].slice(7)
        var lwords = CryptoJS.enc.Base64.parse(lastcont);
        var ltextString = CryptoJS.enc.Utf8.stringify(lwords);
        return ltextString;
    }

};

export const encoder = (name) => {
    const encodedWord = CryptoJS.enc.Utf8.parse(name); // encodedWord Array object
    const encoded = CryptoJS.enc.Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='
    var sting = randomnumber(6)+'='+encoded+'&'+randomnumber(4)+"="+randomnumber(2)+"&"+randomnumber(3)+"="+randomnumber(5);
    const encodedWords = CryptoJS.enc.Utf8.parse(sting); // encodedWord Array object
    const encodeds = CryptoJS.enc.Base64.stringify(encodedWords); // string: 'NzUzMjI1NDE='
    return encodeds;
};


export const randomnumber = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }

    return result;
};