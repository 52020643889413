import React from 'react'
// import { PanePage } from '../Layouts/Mobile/PanePage'
// import { PanelLoginPage } from '../Layouts/PanelLoginPage'
import DeviceIdentifier from 'react-device-identifier';
import { PanelLoginPage2 } from '../Layouts/PanelLoginPage2';
import { MPanelLogin } from '../Layouts/Mobile/MPanelLogin';

export const PanelLogin = ({commonData, toast}) => {
    return (
        <div>
            <DeviceIdentifier isDesktop={true} >
            <PanelLoginPage2 commonData={commonData} toast={toast} />
            </DeviceIdentifier>

                     
          <DeviceIdentifier isMobile={true} isTablet={true} >
          <MPanelLogin commonData={commonData} toast={toast}/>
          </DeviceIdentifier>
           
        </div>
    )
}
