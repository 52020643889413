import React, { useEffect, useState } from 'react'
import { Footer } from './Footer'
import { Header } from './Header'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Pricing } from './Pricing';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactPlayer from 'react-player';

import './css/Pricing.css'
import axios from 'axios';
import { useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

const Home = ({ commonData }) => {
    const phptoken = useSelector(state => state.phpToken);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    document.title = "Bharat Expo";
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
            slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };
    const [ExeLogo, setExeLogo] = useState([])
    useEffect(() => {
        async function getexeicon() {
            let url = commonData.siteurl + '/expo_access_api/getExhitotIcon/';
            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    setExeLogo(res.data[0].DATA);
                }

            }

        }

        getexeicon();
    }, [])

    return (
        <div>
            <Header />
            <div className="homeWB bg-white">
                <div className="website-image-bg-slider123">
                    <div className='player-wrapper'>
                        <ReactPlayer
                            className='react-player'
                            url='/images/website/Video-Banner.mp4'
                            width='100%'
                            height='100%'
                            loop='true'
                            playing='true'
                            muted='true'
                            playsinline= 'true'
                        />
                    </div>
      <div className="website-content-bg123">

                        <h4 animateIn="animate__fadeIn">India’s first 365 days virtual immersive Expo</h4>

                        <div className="website-slider-button pt-3">
                            <Link to="/register"><Button className="btn btnOrange mx-3">Register</Button></Link>
                            <Link to="/login"><Button className="btn btnBlue  mx-3" >Login</Button></Link>
                        </div>
                    </div>

                </div>


                <div className="expo-services">
                    <div className="services-provide">
                        <div className="services-1 animate__animated animate__fadeInDown">
                            <img src="/images/website/service-1.png" alt="Bharat Expo" />
                            <div className="service-details">
                                <h4>Made In India<br />Made for India</h4>
                            </div>
                        </div>

                        <div className="services-1  animate__animated animate__fadeInDown">
                            <img src="/images/website/service-2.png" alt="Bharat Expo" />
                            <div className="service-details">
                                <h4>100K + <br />Visitors</h4>
                            </div>
                        </div>

                        <div className="services-1 animate__animated animate__fadeInDown">
                            <img src="/images/website/service-3.png" alt="Bharat Expo" />
                            <div className="service-details">
                                <h4>500 + <br />Exhibitors</h4>
                            </div>
                        </div>

                        <div className="services-1 animate__animated animate__fadeInDown">
                            <img src="/images/website/service-4.png" alt="Bharat Expo" />
                            <div className="service-details">
                                <h4>24x7 Exhibition <br />Platform</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-expo">

                    <div className="col-md-12">
                        <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce="true">
                            <div className="col-md-6">
                                <h4>About the Expo</h4>
                                <p>Bharat Expo is the 1st ever 365 days immersive virtual
                                    trade expo that enhances the experience of attending
                                    a real conference and exhibition.It is a diverse and virtual
                                    enriching experience for the entrepreneurial community
                                    to build their businesses globally. This event brings together
                                    a unique, borderless virtual community from India.</p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__fadeInRight" animateOnce="true">
                            <div className="col-md-6">
                                <img src="/images/website/ICON_WEB.png" alt="About Bharat Expo" />
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>




                <div className="bookStall">
                    <div id="benefits-exhibitors">
                        <div className="row col-md-12 py-4">
                            <div className="my-2" style={{ textAlign: "center" }}>
                                <h4>Types of exhibitors </h4>
                            </div>

                            <div className="exhibitors-type-1 my-2">
                                <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce="true">
                                    <div className="col-md-2">
                                        <img src="/images/website/manufacture-1.png" alt="About Bharat Expo" />
                                    </div>
                                    <div className="col-md-10">
                                        <h5>Manufacturer & B to B</h5>
                                        <p>From government organizations to private manufacturers. We have a platform for every individual business. </p>

                                    </div>
                                </ScrollAnimation>
                            </div>

                            <div className="exhibitors-type-1 my-2">
                                <ScrollAnimation animateIn="animate__fadeInRight" animateOnce="true">
                                    <div className="col-md-2">
                                        <img src="/images/website/service-providers.png" alt="About Bharat Expo" />
                                    </div>
                                    <div className="col-md-10">
                                        <h5>Service Providers</h5>
                                        <p>From all kinds of services providers like Consulting, legal, real estate, communications, storage and much more. </p>

                                    </div>
                                </ScrollAnimation>
                            </div>

                            <div className="exhibitors-type-1 my-2">
                                <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce="true">
                                    <div className="col-md-2">
                                        <img src="/images/website/Startups-1.png" alt="About Bharat Expo" />
                                    </div>
                                    <div className="col-md-10">
                                        <h5>Startups</h5>
                                        <p>From pin to airplane we have space for everyone. Startups in any sector can be a part to build a brand for their companies.</p>

                                    </div>
                                </ScrollAnimation>
                            </div>

                            <div className="exhibitors-type-1 my-2">
                                <ScrollAnimation animateIn="animate__fadeInRight" animateOnce="true">
                                    <div className="col-md-2">
                                        <img src="/images/website/Franchisers-1.png" alt="About Bharat Expo" />
                                    </div>
                                    <div className="col-md-10">
                                        <h5>Franchisers</h5>
                                        <p>From all kinds of professionals like lawyers,accountant,architects,financial advisers,engineers and much more. </p>

                                    </div>
                                </ScrollAnimation>
                            </div>

                            <div className="exhibitors-type-1 my-2">
                                <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce="true">
                                    <div className="col-md-2">
                                        <img src="/images/website/Consumer-1.png" alt="About Bharat Expo" />
                                    </div>
                                    <div className="col-md-10">
                                        <h5>Consumer Retailers </h5>
                                        <p>From small shops to multi-nationally companies we provide stalls to showcase your products to the masses. </p>

                                    </div>
                                </ScrollAnimation>
                            </div>

                        </div>
                    </div>
                </div>
                <ScrollAnimation animateIn="animate__zoomIn" animateOnce="true">
                    <Pricing />
                </ScrollAnimation>

                <ScrollAnimation animateIn="animate__zoomIn" animateOnce="true">
                    <div className="business-platform">
                        <div className="website-image-bg-slider" style={{ background: "url(/images/website/Banner.jpg)" }}>
                            <div className="content-bg">
                                <h4 className='text-light'>Become a part of a virtually growing business platform</h4>
                                <div className="slider-button">
                                    <Link to="/bookstall">  <Button className="btn btnOrange">Register Now</Button> </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </ScrollAnimation>
                <div className="exhibitors-logos">
                    <h3 className="logo-title my-3"> Exhibitors </h3>
                </div>
                {ExeLogo
                    ?
                    <Carousel responsive={responsive}
                        swipeable={true}
                        draggable={true}
                        autoPlay={true}
                        infinite={true}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                    >

                        {ExeLogo.map((E, index) => (
                            <div className='text-center'>
                                <OverlayTrigger trigger={[ 'click','hover']} rootClose placement="top" overlay={
                                <Popover id={`popover-basic-info`} >
                                    <Popover.Header as="h2">{E.Contact.name}</Popover.Header>
                                    <Popover.Body>

                                        <p className="sscompanydetails" dangerouslySetInnerHTML={{ __html: E.SupportRequest.description }}>

                                        </p>

                                    </Popover.Body>
                                </Popover>}>
                                    <img
                                        className="d-block-client-logo"
                                        src={`${commonData.siteurl}/public/expofiles/${commonData.sitename}/${E.Expo_Files.file_name}`}
                                        alt={E.Contact.name}
                                    />
                                </OverlayTrigger>
                            </div>
                        ))
                        }

                    </Carousel>
                    : ""
                }

            </div>
            <Footer />
        </div>
    )
}

export default Home
