let initial;

    initial = { on: false, status: "", msg: "" ,bg: ""};


const initials = initial;
const userDetails = (state= initials , action ) =>{
    if(action.type === "TOASTNOTIFY" ){
        return {on:action.on, status:action.status, msg:action.msg, bg:action.bg };
    }else{ 
        return state;
    }
   
}

export default userDetails;