let initial;

    initial = { on: true};


const initials = initial;
const OpenMenu = (state= initials , action ) =>{
    if(action.type === "OPENMENU" ){
        return {on:action.on};
    }else{ 
        return state;
    }
   
}

export default OpenMenu;