import React, { useState, useEffect } from 'react';
import './css/Org_Dashboard.css'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BroadCast } from '../Elements/BroadCast';
import { Pageloader } from '../Components/Pageloader';
import { Task } from '../Elements/Task';
import { RecentContact } from '../Elements/RecentContact';
import 'font-awesome/css/font-awesome.min.css';
import Analytics from '../Elements/Analytics';


export const Org_Dashboard = ({ commonData }) => {
    const phptoken = useSelector(state => state.phpToken);
    const user = useSelector(state => state.userDetails);


    const [TotalVisitor, setTotalVisitor] = useState("");
    const [TotalLoggedVisitor, setTotalLoggedVisitor] = useState("");
    const [TotalStall, setTotalStall] = useState("");
    const [TotalAgentStall, setTotalAgentStall] = useState("");
    const [TotalComunication, setTotalComunication] = useState("");
    const [BcStall, setBcStall] = useState([]);
    const [BcHall, setBcHall] = useState([]);
    const [Associate, setAssociate] = useState([]);
    const [pageloader, setPageLoader] = useState(false);
    useEffect(() => {
        async function getorg_dasboarddetails() {
            setPageLoader(true);
            let url = commonData.siteurl + '/virtualexpo_api/getorg_dasboarddetails/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                visitor_id: user.accountid,
                visitor_type: user.account_type
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    setTotalVisitor(res.data[0].DATA.totalvisitor);
                    setTotalLoggedVisitor(res.data[0].DATA.totalloggedvisitor);
                    setTotalStall(res.data[0].DATA.totalstall);
                    setTotalAgentStall(res.data[0].DATA.totalagentstall);
                    setTotalComunication(res.data[0].DATA.totalcommunication);
                    setBcStall(res.data[0].DATA.stall);
                    setBcHall(res.data[0].DATA.hall);
                    setAssociate(res.data[0].DATA.assocontact);
                    setPageLoader(false);
                }

            }

        }

        getorg_dasboarddetails();
    }, []);




    return (
        <div className="container-xxl org-dashboard pt-5">
            <div className="col-12 row mx-0 topbar">
                <div className="col-md-1"></div>
                <div className="col-md-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="icon-place">
                                <img  src={`/stall/icon/Icon-8.png`} />
                            </div>
                            <h4 className="count-place">
                                {pageloader
                                    ? <Pageloader />
                                    : [TotalLoggedVisitor ? TotalLoggedVisitor : 0]
                                }

                            </h4>
                            <h6 className="name-place">
                                Total Logged Visitors
                            </h6>
                        </div>
                    </div>
                </div>

                <div className="col-md-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="icon-place">
                                <img  src={`/stall/icon/Icon-8.png`} />
                            </div>
                            <h4 className="count-place">
                                {pageloader
                                    ? <Pageloader />
                                    : [TotalVisitor ? TotalVisitor : 0]
                                }

                            </h4>
                            <h6 className="name-place">
                                Total Visitors
                            </h6>
                        </div>
                    </div>
                </div>

                <div className="col-md-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="icon-place">
                                <img src={`/stall/icon/Icon-7.png`} />
                            </div>
                            <h4 className="count-place">
                                {pageloader
                                    ? <Pageloader />
                                    : [TotalStall ? TotalStall : 0]
                                }
                            </h4>
                            <h6 className="name-place">
                                Total Stall
                            </h6>
                        </div>
                    </div>
                </div>

                <div className="col-md-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="icon-place">
                                <img  src={`/stall/icon/Icon-6.png`} />
                            </div>
                            <h4 className="count-place">
                                {pageloader
                                    ? <Pageloader />
                                    : [TotalAgentStall ? TotalAgentStall : 0 ]
                                }
                            </h4>
                            <h6 className="name-place">
                                Total Agent Stall
                            </h6>
                        </div>
                    </div>
                </div>

                <div className="col-md-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="icon-place">
                                <img src={`/stall/icon/Icon-5.png`} />
                            </div>
                            <h4 className="count-place">
                                {pageloader
                                    ? <Pageloader />
                                    : [TotalComunication ? TotalComunication : 0]
                                }
                            </h4>
                            <h6 className="name-place">
                                Total Communication
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="col-md-1"></div>
            </div>
            <div className="col-12 row mx-0 my-5 bottombar">
                <div className="col-md-6 mt-4">
                    <BroadCast commonData={commonData} BcStall={BcStall} BcHall={BcHall} />
                </div>
                <div className="col-md-6 mt-4">
                    <Task commonData={commonData} Associate={Associate} />
                </div>
                <div className="col-md-6 mt-4">
                    <RecentContact commonData={commonData} />
                </div>
            
            </div>
            
             <div className="analytics">
            <Analytics commonData={commonData} phptoken={phptoken}  user={user} />
             </div>                   
        </div>
    )
}
