import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import './css/style.css'
export const Header = () => {
    let Location = useLocation();
    const [MobileMenu, setMobileMenu] = useState(false)
    return (
        <div className='webHeader'>
            <nav className="navbar navbar-expand-lg blue-button">
                <div className="container-fluid">
                    {/* <Link className="navbar-brand" to="#">{props.title}</Link> */}
                    <div className="website-logo-header">
                        <Link to="/">
                            <img src="/images/global/weblogo.png" width="150px" height="60px" alt="Bharat Expo" />
                        </Link>
                    </div>
                    {MobileMenu
                        ?
                        <button onClick={() => setMobileMenu(false)} className="navbar-toggler text-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="btn-close btn-close-white text-light"></span>
                        </button>
                        :
                        <button onClick={() => setMobileMenu(true)} className="navbar-toggler text-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon "><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars fa-w-14 fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg></span>
                        </button>
                    }


                    <div className={`collapse navbar-collapse ${MobileMenu ? "show" : ""}`} id="navbarSupportedContent">
                        <ul key="webMenu" className={`header-home-rows pt-3 ${MobileMenu ? "flex-column" : ""}`}>

                            <li key="webHome" className={Location.pathname == "/" ? "active" : ""}>
                                <Link to="/" className="">
                                    Home
                                </Link>
                            </li>
                            <li key="webAbout" className={Location.pathname == "/about" ? "active" : ""}>
                                <Link to="/about" className="">
                                    About
                                </Link>
                            </li>

                            <li key="webBookStall" className={Location.pathname == "/bookstall" ? "active" : ""}>
                                <Link to="/bookstall" className="">
                                    Book Stall
                                </Link>
                            </li>
                            <li key="webContact" className={Location.pathname == "/contact" ? "active" : ""}>
                                <Link to="/contact" className="">
                                    Contact
                                </Link>
                            </li>
                            <li key="webAcces" className={Location.pathname == "/login" ? "active" : ""}>
                                <Link to="/virtual-access" className="btn btnOrange">
                                    Virtual Access
                                </Link>
                            </li>


                        </ul>

                        {/* <NavLink className="website-nav-link px-3" aria-current="page" to="/">Home</NavLink>

                        <NavLink className="website-nav-link px-3" to="/Gallery">Gallery</NavLink>

                        <NavLink className="website-nav-link px-3" to="/Testimonials">Testimonials</NavLink>

                        <NavLink className="website-nav-link px-3" to="/about">About</NavLink>

                        <NavLink className="website-nav-link px-3" to="/contact">Contact</NavLink>

                        <NavLink className="nav-link active1 px-2" to="/login">Virtual Access</NavLink> */}

                    </div>
                </div>
            </nav>
        </div>
    )
}
