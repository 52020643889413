let initial;

    initial = { on: false, notify: "", user: "" , username: "", pic: "" , url: ""};


const initials = initial;
const notifyVideo = (state= initials , action ) =>{
    if(action.type === "NOTIFYVIDEO" ){
        return {on:action.on, notify:action.notify, user:action.user , username:action.username, pic:action.pic , url:action.url };
    }else{ 
        return state;
    }
   
}

export default notifyVideo;