let initial;

    initial = { on: false, actionname : "" , id: "", name: "", called: "", msg: ""};


const initials = initial;
const SocketActions = (state= initials , action ) =>{
    if(action.type === "ACTIONS" ){
        return {on:action.on, actionname:action.actionname, id:action.id, name:action.name, called:action.called, msg:action.msg };
    }else{ 
        return state;
    }
   
}

export default SocketActions;