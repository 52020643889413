import phpToken from "./PhpToken";
import userDetails from "./UserDetails";
import ToastNotify from "./ToastNotify";
import ProductDetailId from "./ProductDetailId";
import AuditoriumVideoPlay from "./AuditoriumVideoPlay";
import HelpDeskVideoPlay from "./HelpDeskVideoPlay";
import ExhibitionVideoPlay from "./ExhibitionVideoPlay";
import { combineReducers } from "redux";
import AlertNotify from "./AlertNotify";
import notifyVideo from "./NotifyVideoModal";
import ChatDetails from "./ChatDetails";
import OpenChat from "./OpenChat";
import CurrentStall from "./CurrentStall";
import OpenMenu from "./OpenMenu";
import PaymentBox from "./PaymentBox";
import DonePayment from "./DonePayment";
import VideoCallChat from "./VideoCallChat";
import SocketActions from "./SocketActions";
import RefVisits from "./RefVisits";
import VisitorSounds from "./VisitorSounds";

const rootReducer = combineReducers({
    phpToken,
    userDetails,
    ToastNotify,
    ProductDetailId,
    AuditoriumVideoPlay,
    HelpDeskVideoPlay,
    ExhibitionVideoPlay,
    AlertNotify,
    notifyVideo,
    ChatDetails,
    OpenChat,
    CurrentStall,
    OpenMenu,
    PaymentBox,
    DonePayment,
    VideoCallChat,
    SocketActions,
    RefVisits,
    VisitorSounds
    
});

export default rootReducer;