let initial;

    initial = { on: false};


const initials = initial;
const OpenChat = (state= initials , action ) =>{
    if(action.type === "OPENCHAT" ){
        return {on:action.on};
    }else{ 
        return state;
    }
   
}

export default OpenChat;