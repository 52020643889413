export const PhpToken = (token) => {
    return {
        type: "PHPTOKEN",
        payload: token
    }
}

export const UserDetails = (details) => {
    return {
        type: "USERDETAILS",
        payload: details
    }
}
export const UserDetailsUpdates = (details) => {
    return {
        type: "USERDETAILS",
        payload: details
    }
}
export const ToastNotify = (on, status, msg, bg) => {
    return {
        type: "TOASTNOTIFY",
        on: on,
        status: status,
        msg: msg,
        bg: bg
    }
}
export const AlertNotify = (on, title, msg, eb_id, stall_name, hall_name, audio) => {
    return {
        type: "ALERTNOTIFY",
        on: on,
        title: title,
        msg: msg,
        eb_id: eb_id,
        stall_name: stall_name,
        hall_name: hall_name,
        audio: audio
    }
}

export const ProductDetailId = (id) => {
    return {
        type: "PRODUCTDETAILID",
        payload: id
    }
}
export const AuditoriumVideoPlay = (action) => {
    return {
        type: "AUDITORIUMVIDEOPLAY",
        payload: action
    }
}
export const HelpDeskVideoPlay = (action) => {
    return {
        type: "HELPDESKVIDEOPLAY",
        payload: action
    }
}
export const ExhibitionVideoPlay = (action) => {
    return {
        type: "EXHIBITIONVIDEOPLAY",
        payload: action
    }
}

export const NotifyVideo = (on, notify, user, username, pic, url) => {
    return {
        type: "NOTIFYVIDEO",
        on: on,
        notify: notify,
        user: user,
        username: username,
        pic: pic,
        url: url
    }
}
export const ChatDetails = (on, user, username, pic, group, member, groupname, grouppic) => {
    return {
        type: "CHATDETAILS",
        on: on,
        user: user,
        username: username,
        pic: pic,
        group: group,
        member: member,
        groupname: groupname,
        grouppic: grouppic,
    }
}
export const OpenChat = (on) => {
    return {
        type: "OPENCHAT",
        on: on,
    }
}
export const CurrentStall = (num) => {
    return {
        type: "CURRENT",
        stall: num,
    }
}
export const OpenMenu = (on) => {
    return {
        type: "OPENMENU",
        on: on,
    }
}
export const VisitorSound= (on) => {
    return {
        type: "VISITORSOUND",
        on: on,
    }
}
export const PaymentBox = (on, amount, currency, contactid, contacttype, name, number, email, mode, site, redirect) => {
    return {
        type: "PAYMENTS",
        on: on,
        amount: amount,
        currency: currency,
        contactid: contactid,
        contacttype: contacttype,
        name: name,
        number: number,
        email: email,
        mode: mode,
        site: site,
        redirect: redirect
    }
}

export const DonePayment = (on) => {
    return {
        type: "PAYMENT",
        on: on,
    }
}
export const callUser = (on, id,name, receiver, caller, stallid) => {
    return {
        type: "CALLING",
        on: on,
        id: id,
        name: name,
        receiver: receiver,
        caller: caller,
        stallid: stallid
    }
}
export const socketAction = (on, actionname, id, name, called, msg) => {
    return {
        type: "ACTIONS",
        on: on,
        actionname: actionname,
        id: id,
        name: name,
        called: called,
        msg: msg
    }
}
export const RefreshVists = (num) => {
    return {
        type: "REFRESHVISIT",
        num: num
    }
}