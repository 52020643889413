let initial;

    initial = { on: false,  user: "" , username: "", pic: "", group: "", member: "", groupname: "", grouppic: ""} ;


const initials = initial;
const ChatDetails = (state= initials , action ) =>{
    if(action.type === "CHATDETAILS" ){
        return {on:action.on, user:action.user , username:action.username, pic:action.pic, group: action.group, member: action.member, groupname: action.groupname, grouppic: action.grouppic };
    }else{ 
        return state;
    }
   
}

export default ChatDetails;