import React from 'react'
// import { Registerpage } from '../Layouts/Registerpage'
import { RegisterPage1 } from '../Layouts/RegisterPage1'
import RegisterPage2 from '../Layouts/RegisterPage2'
import DeviceIdentifier from 'react-device-identifier';
import MRegister from '../Layouts/Mobile/MRegister';
import { useParams } from 'react-router';
export const Register = ({ commonData, toast }) => {
  const { name } = useParams();
  return (
    <div>
      {/* <RegisterPage1 commonData={commonData} toast={toast}/> */}


      <DeviceIdentifier isMobile={true} isTablet={true}>
        <MRegister commonData={commonData} toast={toast} reference={name ? name : ""} />
      </DeviceIdentifier>

      <DeviceIdentifier isDesktop={true}>
        <RegisterPage2 commonData={commonData} toast={toast} reference={name ? name : ""}/>
      </DeviceIdentifier>
    </div>
  )
}
